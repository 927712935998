export default defineAppConfig({
  ui: {
    primary: 'blue',
    gray: 'cool',
    colors: ['blue', 'red', 'green', 'yellow', 'purple', 'orange'],
    
    // Configuration globale des composants
    button: {
      default: {
        color: 'blue',
        variant: 'solid',
        size: 'md',
        ui: {
          rounded: 'rounded-lg',
          font: 'font-medium',
          transition: 'transition-colors duration-200'
        }
      }
    },
    
    selectMenu: {
      default: {
        color: 'blue',
        variant: 'outline',
        size: 'md',
        ui: {
          wrapper: 'relative',
          base: 'relative w-full',
          button: {
            base: 'relative w-full flex items-center justify-between gap-2 rounded-lg text-left cursor-default',
            padding: 'px-3 py-2',
            ring: 'focus:ring-2 focus:ring-primary-500/50 focus:ring-offset-0',
            font: 'font-normal',
            background: 'bg-white dark:bg-gray-900',
            border: 'border border-gray-300 dark:border-gray-700',
            hover: 'hover:bg-gray-50 dark:hover:bg-gray-800'
          }
        }
      }
    },

    input: {
      default: {
        size: 'md',
        color: 'blue',
        variant: 'outline'
      }
    },

    card: {
      background: 'bg-white dark:bg-gray-900',
      divide: 'divide-y divide-gray-200 dark:divide-gray-800',
      ring: 'ring-1 ring-gray-200 dark:ring-gray-800',
      rounded: 'rounded-lg',
      shadow: 'shadow-sm',
      body: {
        padding: 'p-4 sm:p-6'
      }
    }
  }
})

import { default as callbacknBOEmbiNsJMeta } from "/home/vsts/work/1/s/wecoopt.client/pages/auth/linkedin/callback.vue?macro=true";
import { default as _91type_93zaTn3TMfrgMeta } from "/home/vsts/work/1/s/wecoopt.client/pages/coming-soon/[type].vue?macro=true";
import { default as coming_45soonCrvKYmd6w7Meta } from "/home/vsts/work/1/s/wecoopt.client/pages/coming-soon.vue?macro=true";
import { default as create_45missionqymTo2Qc5mMeta } from "/home/vsts/work/1/s/wecoopt.client/pages/create-mission.vue?macro=true";
import { default as indexBjEmfOtnxlMeta } from "/home/vsts/work/1/s/wecoopt.client/pages/index.vue?macro=true";
import { default as loginjZoiGEI9s7Meta } from "/home/vsts/work/1/s/wecoopt.client/pages/login.vue?macro=true";
import { default as _91id_93x80NxnEpEuMeta } from "/home/vsts/work/1/s/wecoopt.client/pages/mission/[id].vue?macro=true";
import { default as missions4wBZz0My0VMeta } from "/home/vsts/work/1/s/wecoopt.client/pages/missions.vue?macro=true";
import { default as TestPageAJX7FqrpYzMeta } from "/home/vsts/work/1/s/wecoopt.client/pages/TestPage.vue?macro=true";
export default [
  {
    name: "auth-linkedin-callback",
    path: "/auth/linkedin/callback",
    meta: callbacknBOEmbiNsJMeta || {},
    component: () => import("/home/vsts/work/1/s/wecoopt.client/pages/auth/linkedin/callback.vue")
  },
  {
    name: "coming-soon",
    path: "/coming-soon",
    component: () => import("/home/vsts/work/1/s/wecoopt.client/pages/coming-soon.vue"),
    children: [
  {
    name: "coming-soon-type",
    path: ":type()",
    component: () => import("/home/vsts/work/1/s/wecoopt.client/pages/coming-soon/[type].vue")
  }
]
  },
  {
    name: "create-mission",
    path: "/create-mission",
    meta: create_45missionqymTo2Qc5mMeta || {},
    component: () => import("/home/vsts/work/1/s/wecoopt.client/pages/create-mission.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/wecoopt.client/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginjZoiGEI9s7Meta || {},
    component: () => import("/home/vsts/work/1/s/wecoopt.client/pages/login.vue")
  },
  {
    name: "mission-id",
    path: "/mission/:id()",
    component: () => import("/home/vsts/work/1/s/wecoopt.client/pages/mission/[id].vue")
  },
  {
    name: "missions",
    path: "/missions",
    meta: missions4wBZz0My0VMeta || {},
    component: () => import("/home/vsts/work/1/s/wecoopt.client/pages/missions.vue")
  },
  {
    name: "TestPage",
    path: "/TestPage",
    component: () => import("/home/vsts/work/1/s/wecoopt.client/pages/TestPage.vue")
  }
]